.import {
  height: 60vh;
  display: flex;
  flex-direction: column;

  .importEmpruntChoice {
    align-self: center;
    margin-bottom: 2%;
  }

  .importTable {
    .rs-table-cell-header .rs-table-cell-content {

      // Permet d'aligner les noms des colonnes sur la droite
      &:not(.alignStart) {
        text-align: right;
      }
    }

    // Permet de mettre en gras les lignes depliees
    .rs-table-cell-expanded,
    .rs-table-cell-expanded~.rs-table-cell {
      font-weight: bold;
    }
  }
}

.importActions {
  display: flex;
  justify-content: end;
}