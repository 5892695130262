.importExportExcelMenu {
    height: 60vh;

    .ui.grid {
        height: 100%;

        .ui.bottom.attached.segment {
            border: none;
            justify-content: space-between;
        }
    }
}