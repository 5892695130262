.panelColumn {
    height: 100%;
    min-height: 12vh;

    .flex.panelCardContent {
        display: flex;
        overflow: hidden;
    
        .panelIconContainer {
            display: flex;
        
            :first-child {
                margin-right: 1vw;
            }
        }
    }

    .panelDataContainer {
        flex: 1;

        // Specifique a certains indicateurs
        .bold {
            font-weight: bold;
        }

        .negative {
            color: red;
        }
        
        .positive {
            color: green;
        }

        .row.solde {
            padding-top: 0;
        }
    }

    .removeIcon {
        cursor: pointer;
        font-size: 1em;
        padding-left: 10px;
    }
}