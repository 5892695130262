.italic {
    font-style: italic;
}

// Permet d'ancrer le header  
.rs-table-header-row-wrapper {
    position: sticky;
    top: 0;

    .rs-table-row-header {
        border-bottom: solid 2px black;
    }
}

// Permet d'ancrer le header  
.rs-table.rs-table-treetable {
    overflow: visible;
}

.imputationTableRow {
    &:focus-within .rs-table-cell {
        background-color: #F8E8EC;
    }

    .commentaireCell {
        .ui.basic.segment {
            margin-right: 1vw !important;
        }

        svg {
            margin: 0 .25vw;
        }
    }

    .rs-table-cell-full-text {
        .noeudLibelleGroup {
            display: flex;
            gap: .25vw;
            //on prend toute la place en enlevant la taille de la fleche et de l'icone et des marges
            width: calc(100% - 30px - 0.5vw - 1.18em - 0.25rem);
        }

        .noeudLibelle {
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &:hover {
            // Permet de corriger le fullText qui ne marche plus après un noeud de profondeur
            z-index: 50 !important;

            .noeudLibelle {
                max-width: unset !important;
                overflow: visible;
                width: auto !important;
            }
        }

        .typeRegroupementIcon {
            margin-right: .5vw;
        }
    }

    &:hover {
        .outilCell.rs-table-cell svg {
            visibility: visible;
        }
    }

    .rs-table-cell-content {
        padding-top: 0;
        padding-bottom: 0;

        // Replique le fonctionnement du FW, car on a besoin d'un input avec des data-*
        .currency {
            text-align: right;
        }
    }

    .outilCell.rs-table-cell {
        .rs-table-cell-content {
            justify-content: end;
        }

        svg {
            visibility: hidden;
        }
    }
}

.ui.secondary.pointing.menu.menuFeuilleSaisie a.item:hover {
    background-color: #F8E8EC !important;
    transition: background-color 0.3s;
}