.ui.accordion.accordionControleCoherence {
    padding-bottom: 3vh;

    .title {
        // Permet d'aligner l'icone et le titre de l'accordion avec un espace entre eux
        align-items: center;
        display: flex;
        gap:.5vw;

        .header {
            margin: unset;
        }
    }
}