.ui.grid.outil {
    &:hover {
        background-color: #f1fafe;
        color: #006594;
        cursor: pointer;

        svg {
            stroke: #006594;
        }
    }

    .row.outilRow {
        flex-wrap: nowrap;
        gap: .5vw;
    }
}