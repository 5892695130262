.treeViewAccordionContent .menu.secondary.pointing {
    .item {
        height: 7vh;
        width: 10%;

        &.tabHeader:first-child {
            width: 15%;

            .icon {
                float: left;
            }
        }

        &.tabHeader:last-child {
            width: 15%;

            .icon {
                float: right;
            }
        }

        .content {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
            text-align: center;
        }
    }
}

.bold {
    font-weight: 700;
}

.menuFeuilleSaisie>* {
    text-align: center;
    justify-content: center;
    width: 12.5%;
}


.tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.tab.active {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.filtresAvances {
    transform: translate(-50%, -50%) !important;
    top: 50% !important;
    left: 50% !important;
    height: fit-content;
    width: 60vw;

    .popup.visible.filtres {
        width: 60vw;
    }
}

.treeViewBar {
    align-items: center;
}

// Permet l'alignement des montants pour correspondre aux champs currency
.montantText {
    padding-right: 14px;
}

i.icon.tab-arrow {
    font-size: 1.8em;
    opacity: 50% !important;
    color: #A61F43;
}