.popup.visible.popupFeuille .ui.grid.feuilleListGrid {
    height: 40vh;
    width: 30vw;
    align-content: flex-start;
    overflow-y: auto;
    justify-content: space-evenly;
}

.feuilleListItem {
    cursor: pointer;
    height: 25%;
}

.feuilleListGrid .row {
    padding: 0 !important;
}

.popupFeuille {
    flex-direction: column;
}