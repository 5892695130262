// Permets d'aligner l'icône avec le texte, et de faire un écart entre eux
.regleCalculOption {
    align-items: center;
    display: flex;
    gap: .5vw;
}

// Gestion du loader
.calculPropositionModal {
    height: 65vh;

    .ui.grid {
        height: 100%;
    }
}

.ui.form {
    .fields.regleCalculGroup {
        .ui.selection.dropdown {
            // Permets de ne pas faire agrandir la Combobox à cause de l'icône
            align-content: center;
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 0;
            padding-top: 0;
        }

        .one.wide.field {
            margin: auto;
        }
    }

    // Affichage fraction
    .calculPropositionOperation {
        .calculPropositionOperationMoyenne {
            display: inline-block;
            text-align: center;
            vertical-align: middle;

            span {
                display: block;
            }

            .denominator {
                border-top: thin solid black;
            }
        }
    }
}