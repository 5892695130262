.regroupements {
  margin: auto;
  width: 70%;

  .ui.basic.segment {
    padding-left: 0;
    padding-right: 0;
  }

  .inactive {
    margin-bottom: 10%;
  }
}

.liaisonChapitre {
  margin: 5% 0;
}