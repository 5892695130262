.ui.fluid.card.controleCoherenceItem {
    .fifteen.wide.column {
        // Suppression du decalage entre l'icone et le texte
        padding-left: unset;
        // Ajoute le retour a la ligne
        .description {
            white-space: break-spaces;
        }
    }

    .one.wide.column {
        text-align: -webkit-center;
    }

    .controleCoherenceIconContainer {
        // Permet d'avoir une icone circulaire et non coupe par le border-radius si directement mis sur le svg
        align-items: center;
        border-radius: 50%;
        display: flex;
        height: 30px;
        justify-content: center;
        width: 30px;

        &.iconError {
            background-color: red;
        }

        &.iconWarning {
            background-color: orange;
        }

        &.iconOk {
            background-color: green;
        }
    }
}