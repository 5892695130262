.cell-measurer {
    &:hover .regleCalculRow .regleCalculActions {
        visibility: visible;
    }

    .regleCalculRow {
        display: flex;

        span {
            flex: 15;
        }

        .regleCalculActions {
            display: flex;
            flex: 1;
            justify-content: space-evenly;
            visibility: hidden;            
        }
    }
}