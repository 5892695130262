.panels {
    width: 100%;
}

.ui.card.indicateurs {
    .description {
        position: relative;
    
        .arrow {
            align-content: center;
            align-items: center;
            border-radius: 50%;
            border: solid white 5px;
            color: #A61F43;
            display: flex;
            font-size: 3em;
            height: 50px;
            justify-content: center;
            opacity: 50% !important;
            position: absolute;
            top: calc(50% - 0.5em);
            width: 50px;
            z-index: 50;
    
            &.start {
                left: 2%;
            }
    
            &.end {
                right: 2%;
            }
        }

        .fullWidth {
            width: 100%;

            & > .panelRow {
                display: inline-block;
                overflow-x: hidden;
                white-space: nowrap;
                width: 100%;

                .indicateurTitle {
                    align-items: center;
                    display: flex !important;
                    height: 36px;
                }
            }
        }
    }

    .panelListContainer {
        float: right;
    }
}