.controleCoherenceModalContent.content {
    height: 75vh;

    .ui.grid {
        height: 100%;

        .toggle {
            // Espace entre le toggle et les accordions
            padding-bottom: 2vh;
        }
    }
}