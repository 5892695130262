.outilTableRow {
    border-radius: .28571429rem;
    border: 1.5px solid #ababab;
    display: inline-flex;
    overflow: auto;

    .outilTableRowName {
        flex: 3;
        padding: 1.5% 0 1.5% 5%;
    }

    .outilTableRowAmount {
        align-items: center;
        border-left: 1.5px solid #ababab;
        display: flex;
        flex: 1;
        justify-content: end;
        padding-right: 1vw;

        &.negativeAmount {
            background-color: #ffe6de;
        }

        &.positiveAmount {
            background-color: #edffed;
        }
    }
}